import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  "https://fuiwyddildjlesbctlvq.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ1aXd5ZGRpbGRqbGVzYmN0bHZxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTU5MTQ3NTEsImV4cCI6MjAxMTQ5MDc1MX0.ufYodgQ7imQqBDTXQTDUW_95Ct24GaV4jz84dW-ZLYQ"
);

const VerifyEmail = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const verifyUser = async () => {
      const { error } = await supabase.auth.verifyOtp({ token_hash: token, type: 'signup' });
      if (error) {
        console.error("Error verifying user:", error);
      } else {
        // User verification was successful
        // Redirect or show a success message
      }
    };

    if (token) {
      verifyUser();
    }
  }, [location]);

  return (
    <div>
      Verifying...
      {/* You can add more UI elements or messages here */}
    </div>
  );
};

export default VerifyEmail;
