import React from 'react';
import { Flex, Box, List, ListItem, Link, Heading } from '@chakra-ui/react';
import Sidebar from './Sidebar';


const BlogSelection = () => (
  <>
    {/* Sidebar */}
    <Sidebar />

    <Flex direction="column" justifyContent="flex-start" h="100vh" bg="white" color="black" alignItems="center" px="15%" ml="200px">
      <Box textAlign="left" my={6}>
        <Heading size="lg" mb={4}>Coming soon...</Heading>
      </Box>
    </Flex>
  </>
);

export default BlogSelection;
