import React from 'react';
import { chakra, Box, Flex, Heading, Text, Link, Image } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import poker_positions from './poker_positions.png'
import inflation from './inflation.png'
import cpi_erp from './cpi_erp.png'
import meta_2018_2023 from './meta_2018_2023.png'
import lagged_multiple_contraction from './lagged_multiple_contraction.png'
import poker_chips from './poker_chips.png'

const FinancialTableStakes = () => (
  <>
    {/* Sidebar */}
    <Sidebar />

    <Flex direction="column" justifyContent="flex-start" h="100vh" bg="white" color="black" alignItems="center" px="15%" ml="200px">
      {/* Blog Title */}
      <Box textAlign="left" my={6}>
        <Heading>Financial Table Stakes</Heading>
            <Text fontSize="lg" fontWeight="semibold">Daniel Bassett</Text> 
            <Text fontSize="sm" mb={2}>September 24, 2023</Text>
        {/* Blog Quote */}
        <Text fontStyle="italic" mt={10} mb={4} borderLeft="4px" borderColor="gray.400" pl={4}>
            "The beautiful thing about poker is that everyone thinks they can play."
          <Text as="span" display="block" mt={2} fontWeight="bold">- Chris Moneymaker</Text>
        </Text>
        <Image src={poker_chips} alt="poker_chips" my={2} maxW="300px" maxH="200px"/>

        {/* Blog Content */}
        <Text mt={4}>
          Whether we like it or not, we are all economic actors. Participants in a market economy wherein most of our decisions have a numerical value. We choose to consume certain things at the exclusion of others. By <chakra.span fontStyle="italic">not</chakra.span> consuming or consuming less, we attempt to save more. We try to invest our savings and take advantage of the power of compound interest. Those who try not to earn or save, find themselves in debt. By doing nothing, we lose financial value.
        </Text>
        <Text mt={4}>
            Our modern world is structured such that we are born into a seat at the poker table, <chakra.span fontStyle="italic">involuntarily</chakra.span>. Some of us may love poker: we find these financial decisions intuitive, logical and fun. Others feel the game is rigged: they're opponents are seated with more chips and better cards. Regardless of how we feel about the game, each hand there is a rake and the rake forces you to play.
        </Text>

        <Heading size="md" mt={10} mb={2}>Our Stakes, Our Position</Heading>
        {/*...*/}
        <Text mb={4}>The key to optimal poker play, known as Game Theory Optimal (GTO), rests on playing one's range statistically optimal against the other play's ranges. Depending on how the other players are playing and your position in the hand, you play can tighter (betting with a more narrow and better range of hands) and looser (better with a wider range of hands, some of which may not be good hands).</Text>
        <Image src={poker_positions} alt="poker_positions" my={4} maxW="600px" maxH="400px" bg="white"/>
        <Text mb={4}>Late position hands are at an advantage because more information means more edge. If I know how eager the other players at the table are to bet or fold, it provides me more information with which to place my bets. Early positions have a disadvantage for the opposite reason. When you have edge, you can make <chakra.span fontStyle="italic">looser</chakra.span> bets (bet with a wider range of hands, even poor ones). When you are in early positions, you have to play with a more narrow range.</Text>
        <Text mb={4} bg="white">An interesting dynamic arises if we consider an alteration to the typical poker game. Let's say we got rid of the ante, also known as the tax, to play the game. Our optimal strategy would suddenly be to only play AA, which is the highest expected value hand pre-flop. Because we aren't be taxed to sit and wait for the best hand, we can sit and wait for the best hand. Therefore, the ante incentivizes the players to play. Without this, the optimal move is to wait for the highest certainty.</Text>
        <Text mb={4}>We can also think about the consequences of varying the blind sizes. For example, we are playing $1/$2 No Limit Hold 'Em and we 10X the blinds to start playing $10/$20 (keeping our stacks the same). Suddenly, everyone at the table has to play much tighter. Given our now smaller stack relative to the betting sizes, we have to play more cautiously and wait for better hands.</Text>
        <Text mb={4} bg="white">These two scenarios, no ante and increasing ante, have implications for our economic poker game.</Text>
        <Text mb={4} bg="white"></Text>

        <Heading size="md" mt={10} mb={2}>The Natural Rake</Heading>
        {/*...*/}
        <Text mb={4}>The inherent tax that occurs regardless of authoritative decision making is the rate of inflation. When an economy grows, the prices of most goods, services and assets rise. Things becoming slightly more expense over time is considered "healthy" (2% inflation). Things becoming much more expensive over time is stressful. On the flip side, things becoming broadly less expensive over time (deflation) is even more stressful as it implies a general contraction in economic activity and money in circulation.</Text>
        <Text mb={4}>Inflation can be thought of as organic table stakes in our poker table. We are being taxed just to exist. We can work hard, increase our labor income, store all that hard earned money in cash in our backyard safe and it will <chakra.span fontStyle="italic">lose value</chakra.span> each passing moment.</Text>
        <Text>U.S. Annual Inflation (CPI) Last ~100 Years</Text>
        <Image src={inflation} alt="inflation.png" my={4} maxW="600px" maxH="400px" bg="white"/>
        <Text mb={4}>An increasing rate of inflation is akin to increasing our poker ante. The <chakra.span fontStyle="italic">nominal</chakra.span> rake has gone up and we have to seek an even higher rate of return to maintain <chakra.span fontStyle="italic">real</chakra.span> buying power. In a sense, the stakes are higher. The return we seek is all the more important as our cash is losing purchasing power at a higher rate. When inflation is high, it is normal to feel financial anxiety: your money is buying less of the things you need. In our game, you have to search for an offsetting higher yield or earnings increase.</Text>
        <Text mb={4}>Ante removal, or zero inflation (and deflation), is akin to waitings for Aces. I can literally store cash in my backyard and it is maintaining it's purchasing power. Therefore, I can wait for an opportunity to go all-in with AA: wait for an obvious, cheap investment.</Text>
        <Image src={cpi_erp} alt="cpi_erp.png" my={4} maxW="600px" maxH="400px" bg="white"/>
        <Text mb={4}>The post-GFC period was the best of both worlds. Inflation remained low and stable, while equity risk premium (excess expected return on equities) remained high and stable. Investors who held assets, pretty much any assets with positive ERP, saw their net worth go up and to the right.</Text>

        <Heading size="md" mt={10} mb={2}>The Perceived Tax</Heading>
        {/*...*/}
        <Text mb={4}>There's another tax on our capital that is illusory yet piercing. At times, it feels like a mystical economics term. Yet, when we consider it's reality we are aware of the ever present <chakra.span fontStyle="italic">opportunity cost</chakra.span> of capital.</Text>
        <Text mb={4}>While abstract, opportunity cost is a consequential concept. When committing time, capital or efforts to one thing, I am closing off the opportunity of other things. If we think of possible futures for ourselves at present, we have an infinite set of paths that could play out in the future. By committing to one path, we close off all of the others.</Text>
        <Text mb={4}>This is particularly true when it comes to capital allocation and our economic poker game. If I have a fixed stack of capital at the present moment, I can only allocate that stack in one configuration at any given time. Sure I can diversify, but I am committing myself to that set of opportunities while holding that portfolio.</Text>
        <Text mb={4}>We see people feel this phenomenon even if they do not use the technical term, opportunity cost. Statements such as "I should've bought Bitcoin in 2010" or "I should've taken that job at X company that ended up doing well". Keep in mind, these are <chakra.span fontStyle="italic">normative</chakra.span> statements about our past experiences.</Text>
        <Text mb={4}>Opportunity cost can be psychologically painful, particularly when it comes to our financial game play. The FOMO meme encapsulates are desire to not miss out on the next big economic opportunity. This dynamic is sometimes effectively used by certain economic actors to extract chips from other players, feeding off our ingrained psychology.</Text>
        <Text mb={4}>Great poker players are incredible at extracting these pyschological weaknesses. In our economic game, it's important for us to fight the anxiety of opportunity cost, the perceived tax, when making decisions. Almost any time the decision is made based on a fear of missing out, it is likely that it is not a prudent decision.</Text>

        <Heading size="md" mt={10} mb={2}></Heading>
        {/*...*/}
        <Text mb={4}></Text>
        <Text mb={4}></Text>
        <Text mb={4}></Text>
        <Text mb={4}></Text>
        <Text mb={20}></Text>
      </Box>
    </Flex>
  </>
);

export default FinancialTableStakes;
