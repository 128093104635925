import React, { useState } from 'react';
import { Box, Icon, VStack, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaHome, FaPen, FaDatabase, FaIdeal, FaBook, FaChevronLeft } from 'react-icons/fa'; 

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false); 

  return (
    <Box width={isCollapsed ? "50px" : "200px"}
     position="fixed" top="0" left="0"
     height="100vh" 
     borderRight="1px solid gray" 
     pr={3} 
     bg="white" 
     zIndex="10">
      <Icon
        as={FaChevronLeft} 
        onClick={() => setIsCollapsed(!isCollapsed)} 
        cursor="pointer" 
        mb={4}
        color="black"
      />
      <VStack align="start" spacing={6} bg="white" ml={2}>
        <ChakraLink as={Link} display="flex" alignItems="center" to="/" color="black">
          <Icon as={FaHome} mr={2} />
          {!isCollapsed && <span>Home</span>}
        </ChakraLink>
        <ChakraLink as={Link} display="flex" alignItems="center" to="/BlogSelection" color="black">
          <Icon as={FaPen} mr={2} />
          {!isCollapsed && <span>Writing</span>}
        </ChakraLink>
        <ChakraLink as={Link} display="flex" alignItems="center" to="/data" color="black">
          <Icon as={FaDatabase} mr={2} />
          {!isCollapsed && <span>Data</span>}
        </ChakraLink>
        <ChakraLink as={Link} display="flex" alignItems="center" to="/ai" color="black">
          <Icon as={FaIdeal} mr={2} />
          {!isCollapsed && <span>AI Tools</span>}
        </ChakraLink>
        <ChakraLink as={Link} display="flex" alignItems="center" to="/books" color="black">
          <Icon as={FaBook} mr={2} />
          {!isCollapsed && <span>Books</span>}
        </ChakraLink>
      </VStack>
    </Box>
  );
}

export default Sidebar;
