import React, {useState} from 'react';
import { Box, Flex, Heading, Text, Image, Link, Input, Button } from '@chakra-ui/react';
import BlogSelection from './BlogSelection';
import futuristic_man_view from './futuristic_man_view.png';
import { financialMarketBlogs, underdogStoriesBlogs } from './BlogSelection';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import Sidebar  from './Sidebar';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Home = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = async () => {
    // Sign up the user
    const { user, error } = await supabase.auth.signUp({
      email: email,
    });

    if (error) {
      console.error("Supabase error:", error.message);
      setMessage('Error subscribing. Email: daniel@aetasgm.com');
    } else {
      // Insert email into the subscribers table
      const { data, error: insertError } = await supabase
        .from('subscribers')
        .insert([{ email }]);

      if (insertError) {
        setMessage('Error saving your email. Please try again.');
      } else {
        setMessage('Thanks for subscribing! Please check your email to verify your subscription.');
        setEmail('');  // Clear the email input after successful subscription
      }
    }
};

  return (
    <>
      {/* Sidebar */}
      <Sidebar />

  <Flex direction="column" justifyContent="flex-start" h="100vh" bg="white" color="black" alignItems="flex-start" px="15%" ml="200px">
    <Box textAlign="left" my={6}>
      <Heading mb={4}>Daniel Bassett</Heading>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Image src={futuristic_man_view} alt="futuristic_man_view" my={2} maxW="300px" maxH="200px"/>
        <Text fontSize="sm">Peering into the future</Text>
      </Box>
      <Text mt={2} mb={4}>I'm an entrepreneur and trader located in New York City. I trade futures, equities and crypto on a mid-frequency timeframe (both quantitative and discretionary). I am the founder of a small hedge fund, Aetas Global Management LLC and I was a co-founder of GradientJ (YCombinator Winter 2023). I focus on the intersection of data science/AI and portfolio management. Currently, I'm on a short sabbatical exploring new ventures and/or roles.
      <p><br></br>This is an unstructured collection of my research and other interests (built myself using ReactJS, Vercel, Supabase). I primarily write about professional risk takers, financial markets and Effective Accelerationism (e/acc).</p></Text>
    </Box>

    {/* LinkedIn and Twitter Icons */}
    <Box d="flex" flexDirection="row" mt={2} justifyContent="space-between" width="15%"> {/* I've increased the width a bit */}
      <Box as="span" mr={3}>
        <Link href="https://www.linkedin.com/in/danielsbassett/" isExternal>
          <FaLinkedin size="24px" style={{ display: 'inline-block' }} /> {/* Ensuring icon is inline-block */}
        </Link>
      </Box>
      <Box as="span">
        <Link href="https://twitter.com/dansilasb" isExternal>
          <FaTwitter size="24px" style={{ display: 'inline-block' }} /> {/* Ensuring icon is inline-block */}
        </Link>
      </Box>
    </Box>

    <Box mt={8} textAlign="left">
      <Heading size="md" mb={4}>New Post:</Heading>
      <Link href={`/${underdogStoriesBlogs[0].replace(/ /g, "-").toLowerCase()}`}>{underdogStoriesBlogs[0]}</Link>
    </Box>
    <Box mt={8} textAlign="left">
      <Heading size="md" mb={4}>Subscribe for Updates:</Heading>
      {message && <Text mb={4} color={message.startsWith('Error') ? 'red.500' : 'green.500'}>{message}</Text>}
      <Input 
        placeholder="Enter your email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)}
        bg="white"  // setting background to white
        color="black"  // setting text color to black
        borderColor="gray.300"  // setting border color
        boxShadow="sm"  // adding a small box shadow
        _placeholder={{ color: 'gray.400' }}
      />
      <Button mt={2} mb={4} onClick={handleSubscribe} bg="blue.500" color="white">Subscribe</Button>
  </Box>
  </Flex>

  </>
);
  };

export default Home;