import React, { useState } from 'react';
import { Flex, Box, Heading, Image, Text, Input, Button } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import cyberpunk_agi from './cyberpunk_agi.png';

const AITools = () => {
    const [file, setFile] = useState(null);
    const [conversionResult, setConversionResult] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://ai-backend-ruby.vercel.app/convert-csv-to-json/', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                setConversionResult(JSON.stringify(result, null, 2));
            } else {
                console.error('Failed to convert file');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Sidebar />

            <Flex direction="column" justifyContent="flex-start" h="100vh" bg="white" color="black" alignItems="flex-start" px="15%" ml="200px">
                <Box textAlign="left" my={6}>
                    <Heading mb={4}>Daniel Bassett</Heading>
                    <Image src={cyberpunk_agi} alt="cyberpunk_agi" my={2} maxW="600px" maxH="400px"/>
                    <Text fontSize="sm">Hobby AI Tools</Text>
                    <Heading size="lg" mt={4} mb={4}>AI Tools</Heading>
                    <Heading size='md' mb={4}>Financial Copilot</Heading>

                    <Input type="file" onChange={handleFileChange} />
                    <Button onClick={handleFileUpload} mt="4">
                        Upload Documents
                    </Button>

                    {conversionResult && (
                        <Box mt="4">
                            <Text>Conversion Result:</Text>
                            <pre>{conversionResult}</pre>
                        </Box>
                    )}
                </Box>
            </Flex>
        </>
    );
};

export default AITools;