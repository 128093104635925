import React, { useState } from 'react';
import { Flex, Box, List, ListItem, Text, Image, Link, Heading, Input, Button } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import cyberpunk_wanderer from './cyberpunk_wanderer.png';
import spxvol from './spx-vol.png';
import spxmonthly from './spx_monthly_returns_Nov2023.png'
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Data = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubscribe = async () => {
      // Sign up the user
      const { user, error } = await supabase.auth.signUp({
        email: email,
      });

      if (error) {
        console.error("Supabase error:", error.message);
        setMessage('Error subscribing. Email: daniel@aetasgm.com');
      } else {
        // Insert email into the subscribers table
        const { data, error: insertError } = await supabase
          .from('subscribers')
          .insert([{ email }]);

        if (insertError) {
          setMessage('Error saving your email. Please try again.');
        } else {
          setMessage('Thanks for subscribing! Please check your email to verify your subscription.');
          setEmail('');  // Clear the email input after successful subscription
        }
      }
  };

  return (
  <>
    {/* Sidebar */}
    <Sidebar />

    <Flex direction="column" justifyContent="flex-start" h="100vh" bg="white" color="black" alignItems="flex-start" px="15%" ml="200px">
      <Box textAlign="left" my={6}>
      <Heading mb={4}>Daniel Bassett</Heading>
        <Image src={cyberpunk_wanderer} alt="cyberpunk_wanderer" my={2} maxW="600px" maxH="400px"/>
        <Text fontSize="sm">Data science exploration</Text>
        <Heading size="lg" mt={4} mb={4}>Data</Heading>
        <Text mt={2} mb={4}>
            Here is some financial data I actively update. I plan to make this more interactive.
            <p>Last update: 11/13/2023</p>
        </Text>
        <Heading size='md' mb={4}>1. SPX and Volatility</Heading>
        <Text mt={2} mb={4}>
            <p>We plot the trailing 10 years of S&P 500 (SPX) price with a couple of volatility measures: the 20-day rolling historical volatility and the Exponentially Weighted Moving Average volatility (EWMA).</p>
        </Text>
        <Image src={spxvol} alt="spxvol" my={2} maxW="900px" maxH="600px"/>
        <Heading size='md' mb={4}>2. SPX Monthly Returns</Heading>
        <Text mt={2} mb={4}>
            <p>Here is the monthly returns of SPX for the past 20 years as of 11/15/2023. We can see thus far in November 2023, that month-to-date is the 11th highest monthly return of the past 20 years.</p>
        </Text>
        <Image src={spxmonthly} alt="spxmonthly" my={2} maxW="900px" maxH="600px"/>
  </Box>
    </Flex>

  </>
  );
};

export default Data;