import React from 'react';
import { chakra, Box, Flex, Heading, Text, Link, Image } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import asset_autocorrelations from './asset_autocorrelations.png'
import log_fed_balance_sheet from './log_fed_balance_sheet.png'
import log_fed_balance_sheet_w_VIX from './log_fed_balance_sheet_w_VIX.png'
import meta_2018_2023 from './meta_2018_2023.png'
import lagged_multiple_contraction from './lagged_multiple_contraction.png'
import strange_memory from './strange_memory.png'

const MarketMemory = () => (
  <>
    {/* Sidebar */}
    <Sidebar />

    <Flex direction="column" justifyContent="flex-start" h="100vh" bg="white" color="black" alignItems="center" px="15%" ml="200px">
      {/* Blog Title */}
      <Box textAlign="left" my={6}>
        <Heading>Market Memory</Heading>
            <Text fontSize="lg" fontWeight="semibold">Daniel Bassett</Text> 
            <Text fontSize="sm" mb={2}>August 30, 2023</Text>
        {/* Blog Quote */}
        <Text fontStyle="italic" mt={10} mb={4} borderLeft="4px" borderColor="gray.400" pl={4}>
          “The reason markets trend is because our minds don’t work properly. When you recall the past you have lots of gaps… The material with which you fill in the gaps in your past recollections is called ‘today’”.
          <Text as="span" display="block" mt={2} fontWeight="bold">- Michael Platt, Bluecrest</Text>
        </Text>
        <Image src={strange_memory} alt="strange_memory" my={2} maxW="300px" maxH="200px"/>

        {/* Blog Content */}
        <Text mt={4}>
          The weak form of the Efficient Market Hypothesis holds that historical prices should not be able to predict future prices. Statistically, market prices should not be highly autocorrelated and trend. In plain English, markets should have no memory. However, many successful market participants have made their career, systematically or discretionarily, on exploiting market memory. I propose a few hypotheses for why markets do show serial correlation in returns.
        </Text>
        <Text mt={4}>
          First, assuming no new information, markets autocorrelate because participants' behavioral factors bias towards their memories (recency bias amongst these). Second, markets trend because non-economic actors, such as the Federal Reserve and Governments, trend in their market intervention behavior. Lastly, market participants are slow to incorporate new information, whether due to structural factors (i.e. passive buyers and sellers) or indolence.
        </Text>

        <Heading size="md" mt={10} mb={2}>The Platt Hypothesis: Markets Trend because Participants have Bad Memory</Heading>
        {/*...*/}
        <Text mb={4}>Humans are born with a myriad of psychological biases. We often overweight our own perception and even misremember events that we directly experienced. </Text>
        <Text mb={4}>One of the well known biases we all succumb to is recency bias. We overweight events and facts that occurred or became known to us in the recent past. Even though something that happened one year ago may be more important than something that happened one week ago, we give a greater weight to the thing that happened a week ago. </Text>
        <Text mb={4}>One can notice this in many forms, but one way is how we feel about emotionally distressing events. Immediately after a distressing event, we are often filled with the strongest emotion: sadness, rage or disgust. However, when we look back on this same event years later, we almost always feel less emotional about it: a certain detachment forms. We may still have sour feelings about it, but we certainly do not have the extreme emotion we once had. </Text>
        <Text mb={4} bg="white">Intuitively, this appears to be one of the stronger psychological biases that affects market participants. We often get caught up in the current narratives (“current thing”) and ascribe a lot of importance to said thing. When Platt talks about our bad memory, this is one of our faults: we struggle to incorporate information equally. </Text>
        <Text mb={4} bg="white">We can run a test for recency bias, albeit, not an academic test (this is an informal blog). By looking at autocorrelation of different asset classes, we can assess the correlation of returns between the present and near term lags (such as a week) and longer term lags (such as one year). </Text>
        <Image src={asset_autocorrelations} alt="asset_autocorrelations" my={4} maxW="600px" maxH="400px" bg="white"/>
        <Text mb={4} bg="white">Again, this is not an academically rigorous exercise (something I whipped up in Jupyter Notebooks). But we can see that 1-week lags generally have the highest autocorrelation across asset classes. In contrast, 1-month lags appear to have the lowest serial correlation, pointing to reversal behavior in returns at the monthly level. While not conclusive, this does illustrate a stronger bias towards more recent data in predicting future data. </Text>

        <Heading size="md" mt={10} mb={2}>Interventionist Government: Markets Trend because Government Behavior Trends</Heading>
        {/*...*/}
        <Text mb={4}>Government behavior, especially as it relates to economics, tends to be slow-moving and trend-following. Once Congress passes a new record setting spending bill, that number becomes the baseline. Once the Federal Reserve embarks on asset purchases (QE), it keeps doing so in perpetuity (effectively). Rarely do you see governments act against the trend of their own behavior and they are usually late to respond to things (see the Fed’s response to recent inflation). </Text>
        <Text mb={4}>This represents a strong case for why trends in financial markets occur: the government trends in it’s spending and market intervention. Therefore, mechanically the markets respond to these purchases and flooding of dollars into the system by following this government behavior. </Text>
        <Text mb={4}>We can take a look by plotting the multiplicative growth of the Fed’s balance sheet, SPX and TLT (long duration bonds) and with the VIX index in the second chart. </Text>
        <Image src={log_fed_balance_sheet} alt="log_fed_balance_sheet.png" my={4} maxW="600px" maxH="400px" bg="white"/>
        <Image src={log_fed_balance_sheet_w_VIX} alt="log_fed_balance_sheet_w_VIX.png" my={4} maxW="600px" maxH="400px" bg="white"/>
        <Text mb={4}>Equities appear to be most strongly correlated with growth in the Fed’s balance sheet. We can clearly see that increases in the Fed’s balance sheet lead prolonged runs in the S&P 500. In attempts at tapering the balance sheet such as 2011 and 2018, we see significant spikes in volatility. However, it is important to note that some of the largest volatility spikes occurred for other macroeconomic reasons (in 2008 and 2020). </Text>
        <Text mb={4}>Interestingly, long duration bonds do appear to be correlated during certain intervals, however, severely decouple during others. The most recent example being the extreme drawdown in long duration given a modest reduction in balance sheet assets. Perhaps this points to the nonlinear consequences of prolonged balance sheet expansion. </Text>

        <Heading size="md" mt={10} mb={2}>Lagged Hypothesis: Participants are Slow to Incorporate Information</Heading>
        {/*...*/}
        <Text mb={4}>This hypothesis is challenging to prove. Also, it is most likely to anger the Efficient Market Hypothesis crowd. “There are so many smart people incorporating information in the markets!” “No way there’s alpha in publicly available information!” are some of the arguments. </Text>
        <Text mb={4}>However, we see these inefficiencies happen all the time, even in popular securities such as large cap technology. The reason is that sudden rates of change in information, such as earnings or revenue surprises, coupled with mechanical factors such as positioning, can cause extreme dislocations. The perfect illustration of this fact is the performance of Meta over the past couple of years. </Text>
        <Image src={meta_2018_2023} alt="meta_2018_2023.png" my={4} maxW="600px" maxH="400px" bg="white"/>
        <Text mb={4}>Meta, one of the most widely held stocks by some of the most sophisticated investors, fell about 75% in less than one year, shedding hundreds of billions in market cap. It then rallies back almost the entirety of the loss in the next 8 months of the following year. This occurs without extreme changes in financial performance or guidance. The rate of change of these metrics did worsen in 2022, but nowhere near the level that would justify this volatility. </Text>
        <Text mb={4}>We clearly see that there is some inefficient incorporation of information in individual securities. But what about in the wider markets, particularly those which are known to trend. This is difficult to test, but one of the ways we can explore this is by looking at periods of extreme macroeconomic change, such as interest rates moving off of the zero bound, and assessing the incorporation of this new information into the broader market. </Text>
        <Image src={lagged_multiple_contraction} alt="lagged_multiple_contraction.png" my={4} maxW="600px" maxH="400px" bg="white"/>
        <Text mb={4}>The Red X marks the earliest indication that the Federal Reserve was going to begin hiking interest rates to fight inflation. Although this was not entirely clear until 2022, and the Blue X marks the first actual hike.</Text>
        <Text mb={4}><chakra.span fontStyle="italic">All else equal</chakra.span>, higher interest rates reduce price multiples because the rate at which earnings are being discounted rises (the divisor gets larger). There are many issues assuming that prices reflect some perfectly discounted future cash flows, but at the very least we can assume that a sudden rise in interest rates, particularly after they have been sitting at zero, would reduce multiples in the intermediate term. A market that responds to this fact would incorporate this quickly. In the short term, given that fundamentals have not changed, the increase in rates should reduce equity multiples. </Text>
        <Text mb={4}>The overlay in sentiment should theoretically express the expectation in participants' view of forward price behavior. Although, we all know that sentiment tends to reflect the past or the present rather than the future. Interestingly, with the change in rates there was a slight lead in sentiment prior to the sell off in early 2022. However, we see that sentiment does not generally lead the trend but follows the trend. The evidence here points to the reflexivity of trends and how participants generally reinforce existing trends while being laggards on a forward looking basis. </Text>

        <Heading size="md" mt={10} mb={2}>Conclusion</Heading>
        {/*...*/}
        <Text mb={4}>Qualitatively, we see trends arise in social dynamics. The term “trendy” is used to describe clothing that is fashionable or restaurants that are currently popular in New York City. We see high near term autocorrelation in these types of trends: trendy restaurants in NYC today were also very trendy 6 months ago, but few were also trendy 6 years ago. This might be the differentiation between “value” restaurants and “meme” restaurants. Meme restaurants experience short bursts of momentum, only to fall out of trend quickly. Value restaurants have lower volatility and more consistent positive drift. </Text>
        <Text mb={4}>We see cultural trends. Many political identities and their respective belief systems were not relevant two decades ago, but are quite influential in the cultural landscape of today. Playing certian sports can become a sort of trend. Pickleball has suddenly become a popular sport amongst young adults, yet it did not exist several years ago. Again, we have a sort of mimetic dynamic at play: there isn’t a sound fundamental reason for this shift. </Text>
        <Text mb={4}>Technology arcs are these sort of mega-trends. The differentiating factor is distinguishing between “in trend” technologies that have a fundamental value associated with them and those that don’t. The common argument we hear today is that AI has a high fundamental value whereas Crypto did not. However, both were “in trend” at one point in time. The AI wave looks fundamentally valuable, but we won’t know for a fact for several years. </Text>
        <Text mb={4}>George Soros illuminated how trends are reflexive. As trends build and continue, we reflexively believe them further, adding fuel to the fire. As certain market trends upwards, we believe more and more the price appreciation will continue, possibly even ascribing explanations such as “exponential growth”, which leads to further buying and price appreciation. When you are in a trend, such as a technology arc or fashion trend, it is hard (and sometimes foolish) to bet against it, as there is a strong reflexive momentum embedded.</Text>
        <Text mb={20}>From these examples, we can see that perhaps the price trend can be due to investment decisions such as “people I know are buying X asset” or “it went up yesterday so it seems like it will go up today”. The embedded relfexivity then proves those that are long trend "correct" and the trend continues. That is, until the trend moves in another direction (we'll save that for another post). </Text>
      </Box>
    </Flex>
  </>
);

export default MarketMemory;
