import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './home';
import theme from './theme';
import BlogSelection from './BlogSelection';
import Data from './Data';
import AITools from './AITools';
import ComingSoon from './ComingSoon';
import MarketMemory from './MarketMemory';
import FinancialTableStakes from './FinancialTableStakes';
import JayGould from './JayGould';
import VerifyEmail from './verify-email';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/BlogSelection" element={<BlogSelection />} />
          <Route path="/data" element={<Data />} />
          <Route path="/ai" element={<AITools />} />
          <Route path="/books" element={<ComingSoon />} />
          <Route path="/financial-table-stakes" element={<FinancialTableStakes />} />
          <Route path="/market-memory" element={<MarketMemory />} />
          <Route path="/jay-gould" element={<JayGould />} />
          <Route path="/logistics-of-commodity-futures-curves" element={<ComingSoon />} />
          <Route path="/genghis-khan" element={<ComingSoon />} />
          <Route path="/verify-email" component={VerifyEmail} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
