import React, { useState } from 'react';
import { Flex, Box, List, ListItem, Text, Image, Link, Heading, Input, Button } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import wanderer1 from './wanderer1.png';
import { createClient } from '@supabase/supabase-js';

export const financialMarketBlogs = [
  'Financial Table Stakes',
  'Market Memory',
];

export const underdogStoriesBlogs = [
  'Jay Gould',
];

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const BlogSelection = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubscribe = async () => {
      // Sign up the user
      const { user, error } = await supabase.auth.signUp({
        email: email,
      });

      if (error) {
        console.error("Supabase error:", error.message);
        setMessage('Error subscribing. Email: daniel@aetasgm.com');
      } else {
        // Insert email into the subscribers table
        const { data, error: insertError } = await supabase
          .from('subscribers')
          .insert([{ email }]);

        if (insertError) {
          setMessage('Error saving your email. Please try again.');
        } else {
          setMessage('Thanks for subscribing! Please check your email to verify your subscription.');
          setEmail('');  // Clear the email input after successful subscription
        }
      }
  };

  return (
  <>
    {/* Sidebar */}
    <Sidebar />

    <Flex direction="column" justifyContent="flex-start" h="100vh" bg="white" color="black" alignItems="flex-start" px="15%" ml="200px">
      <Box textAlign="left" my={6}>
      <Heading mb={4}>Daniel Bassett</Heading>
        <Image src={wanderer1} alt="wanderer1" my={2} maxW="300px" maxH="200px"/>
        <Text fontSize="sm">Research on risk</Text>
        <Heading size="lg" mt={4} mb={4}>Writing:</Heading>
        <Heading size="md" mb={2} mt={5}>Stories of Risk</Heading>
        <List spacing={3}>
          {underdogStoriesBlogs.map((blog, index) => (
            <ListItem key={index}>
              <Link href={`/${blog.replace(/ /g, "-").toLowerCase()}`}>{blog}</Link>
            </ListItem>
          ))}
        </List>
        <Heading size="md" mb={2}>Financial Markets</Heading>
        <List spacing={3}>
          {financialMarketBlogs.map((blog, index) => (
            <ListItem key={index}>
              <Link href={`/${blog.replace(/ /g, "-").toLowerCase()}`}>{blog}</Link>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box mt={8} textAlign="left">
      <Heading size="md" mb={4}>Subscribe for Updates:</Heading>
      {message && <Text mb={4} color={message.startsWith('Error') ? 'red.500' : 'green.500'}>{message}</Text>}
      <Input 
        placeholder="Enter your email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)}
        bg="white"  // setting background to white
        color="black"  // setting text color to black
        borderColor="gray.300"  // setting border color
        boxShadow="sm"  // adding a small box shadow
        _placeholder={{ color: 'gray.400' }}
      />
      <Button mt={2} mb={4} onClick={handleSubscribe} bg="blue.500" color="white">Subscribe</Button>
  </Box>
    </Flex>

  </>
  );
};

export default BlogSelection;
