import React from 'react';
import { chakra, Box, Flex, Heading, Text, Link, Image } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import jay_gould from './jay_gould.png'

const JayGould = () => (
  <>
    {/* Sidebar */}
    <Sidebar />

    <Flex direction="column" justifyContent="flex-start" h="100vh" bg="white" color="black" alignItems="center" px="15%" ml="200px">
      {/* Blog Title */}
      <Box textAlign="left" my={6}>
        <Heading>Jay Gould</Heading>
            <Text fontSize="lg" fontWeight="semibold">Daniel Bassett</Text> 
            <Text fontSize="sm" mb={2}>November 12, 2023</Text>
        {/* Blog Quote */}
        <Text fontStyle="italic" mt={10} mb={4} borderLeft="4px" borderColor="gray.400" pl={4}>
            "The Commodore didn't know it but another genius was emerging, one who was studying his methods, taking in the lessons, and sizing up Vanderbilt's character and vulnerabilities. This was another self-made man who grew up on a farm with nothing to his name, another railroad operator with a brilliant and cunning mind, a man who would soon prompt Vanderbilt to look upon him with grudging respect and call him 'the smartest man in America.'"
          <Text as="span" display="block" mt={2} fontWeight="bold">- Greg Steinmetz</Text>
        </Text>
        <Image src={jay_gould} alt="poker_chips" my={2} maxW="300px" maxH="200px"/>

        {/* Blog Content */}
        <Text mt={4}>
            A fascinating and cunning historical figure with a bad reputation, Jay Gould often encompasses charicatures of Wall Street greed. However, venturing <chakra.span fontStyle="italic">beyond good and evil</chakra.span>, we take a look into the man's life and his rise from nothing to one of the great early speculators and financiers in America. 
        </Text>
        <Text mt={4}>
            Greg Steinmetz's <chakra.span fontStyle="italic">American Rascal: How Jay Gould Built Wall Street's Biggest Fortune</chakra.span>, takes an unbiased view into the life of perhaps the most mysterious of America's "Robber Barons". What we find is one of the greatest financial risk takers, who primarily risked his own capital day in and day out, to build an astonishing fortune. But how did a man born on a farm in upstate New York come to challenge the immense power of Cornelius Vanderbilt while Gould was still up and coming? I've identified a few financial strategies, many of which are illegal according to current securities laws yet that were legal at the time, that Gould understood and executed to perfection. While these strategies are not to be employed today, the underpinnings and modifications can be useful even for a modern market participant.
        </Text>

        <Heading size="md" mt={10} mb={2}>Understanding Float</Heading>
        {/*...*/}
        <Text mb={4}>Jay Gould embarked on an adventure in which he entered a trade based on a thesis about the biggest of counterparties: the President of the United States. Civil war hero, President Ulysses S. Grant, was an original gold believer. Gould believed he could profit on a rise in the price of gold (pun intended) because President Grant and then time Treasury Secretary would restric the supply of gold unto the market. Modern day speculators bet on the supply of bonds into the Treasury market, whereas previously, this also involved gold as governments largely controlled supply. Where the story gets suspect, was his trying to influece President Grant and his cabinet to continue to restrict the supply of gold while he already had a long position. The story ends with these antics causing a great deal of turmoil in the gold markets.</Text>
        <Text mb={4}>Our analysis is not concerning itself with the specifics of influencing presidential cabinets, but is focused on the market mechanics of this story. What Gould understood is that prices fundamentally are influenced by supply and demand. In other words, the idea of the <chakra.span fontStyle='italic'>float</chakra.span> of a security that is traded: how many units of that security are available to trade on the market. If the supply of those units increases (i.e. a company issues more shares), all else equal, the price of the security will decrease. If the supply of each of those units decreases (i.e. stock buybacks), all else equal, the price of each of those units increases.</Text>
        <Text mb={4}>In the real world, there are more dynamics and complexities than only looking at float in a vacuum. But it is one of the most important drivers of price in publicly traded financial securities. This can be taken further if we consider the mechanics of positions (long/short) as a proportion of total float. Let's assume each security has 100% of it's available stock to trade long or short (this is not necessarily true in practice, due to leverage and other factors). Security A has 90% of it's float long and 10% of it's float short on average. Security B has 80% of it's float long and 20% of it's float short on average.</Text>
        <Text mb={4}>Next, let's assume a bullish news event occurs for the sector of which the securities are a part. Which security is more likely to experience a "fatter right tail"/higher upside scenario? The answer is Security B, due to it's higher percentage of shorts that will have to cover their shorts as the price increases, driving further upside.</Text>
        <Text mb={20}>Understanding float is a clear and common sense framework for thinking about security movement for speculation or portfolio management. It was clearly a frameowrk that Jay Gould utilized in amassing great wealth through trading.</Text>

        <Heading size="md" mt={10} mb={2}>Mastering Shorting</Heading>
        {/*...*/}
        <Text mb={4}>I have a theory that what separates great traders from the mediocre is the ability to have a strong <chakra.span fontStyle="italic">short</chakra.span> game. The ability to profit from the downside in publicly listed securities not only acts as a hedge to long positions in the portfolio, but presents a unique opportunity to take the other side of what 99% of market participants do: merely longing stocks. What's special about the public markets is that we get to engage in more sophisticated activities than merely being long assets. In the private markets this is not availabe: I'd love the opportunity to short certain venture capital investments or individial real estate, but this is not available mechanically (some startups have thought about this, but I think the problem is liquidity). I digress.</Text>
        <Text mb={4}>Jay Gould certainly supports my theory on shorting. He was a savage on the short side. The data could be compiled, but it appeared some of his biggest wins were shorting stocks rather than being long.</Text>
        <Text mb={4}>He engaged in the now illegal activity of shorting a stock and then releasing negative news about the victim company.</Text>
        <Text mb={4}>Back to our discussion of the supply and demand dynamics of stock float, Gould, with his mastery of float, understood that he could drive down a price with selling pressure but shorting the stock. He would often do this to target companies which he wanted to buy on the cheap. Thus, he would flip long once the price was lowered and become a bullish owner.</Text>
        <Text mb={20}>It is important to note, however, that shorting comes with high risk. The maximum you can lose on a long position is if the stock goes to zero. The maximum you can lose on a short position is <chakra.span fontStyle='italic'>infinite</chakra.span>. This is because there is not limit to which a stock can rise in theory, while it can only go down to zero in the worst case. The approach Gould used, and that which is generally the best approach to shorting, is to short securities for <chakra.span fontStyle="italic">trading</chakra.span> and not for a longer term position. As the window of time increases, there is greater risk that a security could experience a large upside move or a short squeeze.</Text>

        <Heading size="md" mt={10} mb={2}>Leveraged Trading</Heading>
        {/*...*/}
        <Text mb={4}>It may not always be true that behind every fortune there is a crime. Yet it appears accurate that behind every fortune there is either a crime, leverage or an irrational risk (or some conmbination of the three). Gould was no exception. In fact, he may have checked off all three.</Text>
        <Text mb={4}>His antics with Union Pacific were incredible. While he may have known a bit about operating railroads, his control of the company was due to his financial prowess, not his railroad mind.</Text>
        <Text mb={4}>Gould loved holding control over Union Pacific via stock ownership for his speculative purposes. He often paired this with other large stock positions and speculative trades. To facilitate all of this activity, he used <chakra.span fontStyle='italic'>leverage</chakra.span>.</Text>
        <Text mb={4}>During one period, volatility in his long held securities was particularly high and his positions were being targeted by a group of short sellers on Wall Street. He was levered and the lenders were beginning to pressure him to sell securities. It ended with him losing control of certain companies but escaping without losing his fortune.</Text>
        <Text mb={20}>What Gould learned is the danger of leverage. Through the rest of his life, he would avoid such risk. But what the Kelly Criterion tells us is that leverage almost always kills you financially, at some point. If I lever up my stock positions to obtain more exposure and generate higher returns, certainly the higher rate of return is nice. But in the rare case that the portoflio falls a massive amount, my drawdown is amplified. I can be ruined in this case and lose the chance to continue compounding.</Text>

        <Heading size="md" mt={10} mb={2}>Final Thoughts</Heading>
        {/*...*/}
        <Text mb={4}>While we did not focus our attention on Jay Gould's operation of groundbreaking railroads like the Union Pacific, he did make great contributions to the expansion of railroads throughout the country. However, Gould's skill and focus was always around financial engineering and risk taking. His ability to calculate the odds, and outmaneuver kingpins like Cornelius Vanderbilt, carried him to be one of the most wealthy Americans of his time. He wasn't afraid to challenge the power of the U.S. government and speculate on the most grand forces of his time. Those of us who trade today, can salute Jay Gould as an intriguing example of doing what it takes within the rules of his time to win in the markets.</Text>
        <Text mb={4}></Text>
        <Text mb={4}></Text>
        <Text mb={4}></Text>
        <Text mb={20}></Text>
      </Box>
    </Flex>
  </>
);

export default JayGould;
